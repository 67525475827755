import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllDataByIDAPI, filterByParams } from '../utils/api';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { FaRegEye } from 'react-icons/fa';
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { numberFormat } from '../components/numberFormat';
import moment from 'moment';
import { FiRefreshCcw } from 'react-icons/fi';

const AllOrder = () => {
    const ref = useRef(null);
    const [order, setOrder] = useState([]);
    const [isLoading, setIsloading] = useState(false);
    const dealerid = localStorage.getItem('dealerdata');
    const navigate = useNavigate();

    const [currentPage, setCurrentPage] = useState(1);
    const recordPerPage = 50;
    const lastIndex = currentPage * recordPerPage;
    const firstIndex = lastIndex - recordPerPage;
    const records = order.slice(firstIndex, lastIndex);
    const npage = Math.ceil(order.length / recordPerPage)
    const numbers = [...Array(npage + 1).keys()].slice(1)
    const [filtervalues, setFilterValue] = useState();

    const [filtervalidated, setFilterValidated] = useState(false);

    useEffect(() => {
        if (dealerid) {
            getAllOrders(dealerid)
        }
        else {
            navigate("/");
        }
    }, [dealerid])

    const getAllOrders = (dealerid) => {
        setIsloading(true);
        fetchAllDataByIDAPI(`dealer-get-own-order-data/${dealerid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setOrder(...[res.data.orders]);
            }
            else {
                setIsloading(false);
                setOrder([]);
            }
        })
    }

    const handleOrderFilter = async (event) => {
        setIsloading(true);
        event.preventDefault();
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.stopPropagation();
        }
        else {
            try {
                const filterdata = {
                    dealer_id: dealerid,
                    f_order_id: filtervalues.f_order_id,
                    f_order_sdate: filtervalues.f_order_sdate,
                    f_order_edate: filtervalues.f_order_edate,
                    f_order_dstatus: filtervalues.f_order_dstatus,
                }
                filterByParams('dealer-get-filter-order-data', filterdata).then((res) => {
                    if (res.data.status === 1) {
                        setIsloading(false);
                        setOrder(...[res.data.orders]);
                    }
                    else {
                        setIsloading(false);
                        setOrder([]);
                    }
                })
            }
            catch (error) {
                toast.error("Something is worng!!");
            }
        }
        setFilterValidated(true);
    };
    const resetOrder = () => {
        getAllOrders();
        ref.current.reset();
    }
    let handleFilterInputs = e => {
        setFilterValue({
            ...filtervalues,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div className="misDashboard">
            <Helmet>
                <title>SATKARTAR:ALL ORDERS</title>
            </Helmet>
            <ContentWrapper>
                <div className="page-wrapper breadcum">
                    <div className="page-breadcrumb d-none d-sm-flex align-items-center">
                        <div className="d-flex">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 p-0">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard">Home</Link>
                                    </li>
                                    <li className="breadcrumb-item" aria-current="page">All ORDERS</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div className="customFilter">
                        <div className="card border-top border-0 border-4 border-danger">
                            <div className="card-body">
                                <div className="card-title d-flex align-items-center">
                                    <div className="form-heading">
                                        <h5 className="mb-0 text-danger">Order Filter</h5>
                                    </div>
                                </div>
                                <hr />
                                <div className="row">
                                    <div className="col-12 col-lg-12">
                                        <Form ref={ref} noValidate validated={filtervalidated} onSubmit={handleOrderFilter} method="GET">
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>Order ID</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="Search By Order ID"
                                                        name="f_order_id"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>Start Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_sdate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>End Date</Form.Label>
                                                    <Form.Control
                                                        type="date"
                                                        placeholder="Enter the Mobile Number"
                                                        name="f_order_edate"
                                                        onChange={handleFilterInputs}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" controlId="validationCustom10">
                                                    <Form.Label>Search By Delivery Status</Form.Label>
                                                    <Form.Select
                                                        name="f_order_dstatus"
                                                        onChange={handleFilterInputs}>
                                                        <option value="">Select Delivery Status</option>
                                                        <option value="New Order">New Order</option>
                                                        <option value="Delivered">Delivered</option>
                                                        <option value="Canceled">Canceled</option>
                                                    </Form.Select>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2" style={{ marginTop: "35px" }}>
                                                    <Button type="submit" className="btn btn-sm btn-primary">Submit</Button>
                                                    <span style={{ marginLeft: "10px" }} onClick={resetOrder} className="btn btn-sm btn-secondary"><FiRefreshCcw /> Refresh</span>
                                                </Form.Group>
                                            </Row>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card tablecard">
                        <div className="table-responsive">
                            <Table size="sm" className="table-sm" responsive="lg" striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Total Amount</th>
                                        <th>Adv Amount</th>
                                        <th>Balance Amount</th>
                                        <th>State</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Payment Method</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isLoading ? (
                                        [...Array(6)].map((_, i) => (
                                            <tr key={i}>
                                                {Array.from({ length: 13 }).map((_, index) => (
                                                    <td key={index}>
                                                        <Skeleton animation="wave" variant="text" />
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    ) : records.length > 0 ? (
                                        records.map((record, index) => (
                                            <tr key={record?.crm_order_id}>
                                                <td>{index + 1}</td>
                                                <td><Badge bg="success">{record?.order_number}</Badge></td>
                                                <td>{record?.order_name}</td>
                                                <td>{record?.order_mobile}</td>
                                                <td>{numberFormat(record?.grand_total)}</td>
                                                <td>{numberFormat(record?.order_advance_amt)}</td>
                                                <td>{numberFormat(record?.total_balance)}</td>
                                                <td>{record?.order_state}</td>
                                                <td>{moment(record?.created_date).format('Do MMM YYYY')}</td>
                                                <td>{record?.created_time}</td>
                                                <td>{record?.shipping_status}</td>
                                                <td>{record?.payment_method}</td>
                                                <td>
                                                    <Link target="_blank" to={`/order-invoice/${record.order_number}`} className="badge badge-success">
                                                        <FaRegEye style={{ fontSize: "20px" }} />
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td className="text-center" colSpan={13}>Data not found.</td>
                                        </tr>
                                    )}
                                </tbody>

                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th>Order No.</th>
                                        <th>Name</th>
                                        <th>Mobile</th>
                                        <th>Total Amount</th>
                                        <th>Adv Amount</th>
                                        <th>Balance Amount</th>
                                        <th>State</th>
                                        <th>Order Date</th>
                                        <th>Order Time</th>
                                        <th>Delivery Status</th>
                                        <th>Payment Method</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                            </Table>
                            <nav>
                                <ul className="pagination justify-content-end round-pagination">
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={perPage}>Previous</a>
                                    </li>
                                    {
                                        numbers.map((n, i) => (
                                            <li className={`page-item ${currentPage === n ? 'active' : ''}`} key={i}>
                                                <a href="#" className="page-link" onClick={() => changePage(n)}>{n}</a>
                                            </li>
                                        ))
                                    }
                                    <li className="page-item">
                                        <a href="#" className="page-link" onClick={nextPage}>Next</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
    function perPage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changePage(id) {
        setCurrentPage(id)
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
}

export default AllOrder