import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import ContentWrapper from '../components/contentwrapper/ContentWrapper'
import { useNavigate } from 'react-router-dom'
import { getSingleDataFromDatabase } from '../utils/api'
import { toast } from 'react-toastify'
import Skeleton from 'react-loading-skeleton'

const MyProfile = () => {
    const dealerid = localStorage.getItem('dealerdata');
    const [dealer, setDealer] = useState([]);
    const navigate = useNavigate();
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        if (dealerid) {
            getDashboardSingleEmpData(dealerid);
        }
        else {
            navigate("/");
        }
    }, [dealerid]);

    const getDashboardSingleEmpData = (dealerid) => {
        setIsloading(true);
        getSingleDataFromDatabase(`dealer-get-single-data/${dealerid}`).then((res) => {
            if (res.data.status === 1) {
                setIsloading(false);
                setDealer(...[res.data.dealerdata]);
            }
            else {
                setIsloading(true);
                toast.error("Check you connection");
            }
        })
    }

    return (
        <div className="myprofile">
            <Helmet>
                <title>SATKARTAR:MY PROFILE</title>
            </Helmet>
            <ContentWrapper>
                <div className="row">
                    <div className="col-6 col-lg-6 col-xl-6">
                        <div className="card radius-10 overflow-hidden w-100">
                            <div className="card-header border-bottom bg-transparent">
                                <div className="d-flex align-items-center">
                                    <div>
                                        <h6 className="mb-0" style={{ color: "#03a9f4", fontWeight: "500" }}>My Profile</h6>
                                    </div>
                                </div>
                            </div>
                            {isLoading ?
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <Skeleton animation="wave" variant="text" />
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="myprofilesection">
                                    <div className="row">
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Dealer Code:</span>
                                                <span className="value">{dealer?.dealer_code}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Name:</span>
                                                <span className="value">{dealer?.dealer_name}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Mobile:</span>
                                                <span className="value">{dealer?.dealer_mobile}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Email:</span>
                                                <span className="value">{dealer?.dealer_email}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">City:</span>
                                                <span className="value">{dealer?.dealer_city}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">State:</span>
                                                <span className="value">{dealer?.dealer_state}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Country:</span>
                                                <span className="value">{dealer?.dealer_country}</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-6 col-xl-6">
                                            <div className="profilebody">
                                                <span className="key">Pincode:</span>
                                                <span className="value">{dealer?.dealer_pincode}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}

export default MyProfile