import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from 'react';
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import AllOrder from "./pages/AllOrder";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Invoice from "./pages/Invoice";

const INACTIVITY_TIMEOUT = 5 * 60 * 1000;

function AppWithInactivity() {
  const navigate = useNavigate();
  const timeoutRef = useRef(null); 

  const handleLogout = () => {
    localStorage.clear(); 
    toast.warning("You have been logged out due to inactivity.");
    navigate("/"); 
  };

  const startInactivityTimer = () => {
    timeoutRef.current = setTimeout(() => {
      handleLogout(); 
    }, INACTIVITY_TIMEOUT);
  };

  const resetInactivityTimer = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current); 
    }
    startInactivityTimer(); 
  };


  useEffect(() => {
    document.addEventListener("mousemove", resetInactivityTimer);
    document.addEventListener("keydown", resetInactivityTimer);
    document.addEventListener("click", resetInactivityTimer);

    startInactivityTimer();

    return () => {
      document.removeEventListener("mousemove", resetInactivityTimer);
      document.removeEventListener("keydown", resetInactivityTimer);
      document.removeEventListener("click", resetInactivityTimer);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/all-orders" element={<AllOrder />} />
        <Route path="/order-invoice/:slug" element={<Invoice />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <ToastContainer position="bottom-center" />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppWithInactivity />
    </BrowserRouter>
  );
}

export default App;
