import React, { useState, useEffect } from 'react';
import favicon from "../assets/favicon.png";
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { FaRegHandPointRight } from 'react-icons/fa';
import { addDataAPI } from '../utils/api';

const Home = () => {
    const [values, setValues] = useState({ dealer_code: '', dealer_password: '' });
    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);

    const dealerid = localStorage.getItem('dealerdata');

    // Redirect to dashboard if already logged in
    useEffect(() => {
        if (dealerid) {
            navigate("/dashboard");
        }
    }, [dealerid, navigate]);

    const handleInputs = (e) => {
        const { name, value } = e.target;
        setValues(prev => ({ ...prev, [name]: value }));
    };

    // Handle login form submission
    const handleLoginSubmit = async (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        setValidated(true); // Set validated to true at the beginning of submission

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setLoading(true);
            try {
                const res = await addDataAPI("dealer-login-api", values);
                if (res.data.status === 1) {
                    localStorage.setItem("dealerdata", res.data.dealerdata);
                    toast.success(res.data.msg);
                    navigate("/dashboard");
                } else {
                    toast.error(res.data.msg);
                }
            } catch (error) {
                toast.error("An error occurred. Please try again.");
            } finally {
                setLoading(false); // Move loading state reset to finally
            }
        }
    };

    return (
        <div className="homePage">
            <Helmet>
                <title>SATKARTAR DEALER LOGIN</title>
            </Helmet>
            <div className="container h-100">
                <div className="d-flex justify-content-center h-100">
                    <div className="user_card">
                        <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={favicon} className="brand_logo" alt="Logo" />
                            </div>
                            <h2 className="loginname form_container">DEALER LOGIN</h2>
                        </div>
                        <div className="d-flex justify-content-center">
                            <Form noValidate validated={validated} onSubmit={handleLoginSubmit} method="post">
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Label>Enter Code</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Enter Dealer Code"
                                            name="dealer_code"
                                            value={values.dealer_code}
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid dealer code.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            placeholder="Password"
                                            name="dealer_password"
                                            value={values.dealer_password}
                                            onChange={handleInputs}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            <FaRegHandPointRight /> Please provide a valid password.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Row>
                                <Button 
                                    className="d-flex m-auto" 
                                    size='md' 
                                    type="submit" 
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <Spinner size="sm" animation="border" /> 
                                            Submitting...
                                        </>
                                    ) : (
                                        "Submit"
                                    )}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
